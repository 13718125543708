import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Avatar,
} from '@mui/material';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const UserProfile = ({ onComplete, isMobile }) => {
  const [sex, setSex] = useState('');
  const [ageRange, setAgeRange] = useState('');
  const [district, setDistrict] = useState('');
  const [stage, setStage] = useState('sex');
  const theme = useTheme();

  const steps = ['Sex', 'Age', 'District'];
  const currentStep = stage === 'sex' ? 0 : stage === 'age' ? 1 : 2;

  const handleNext = () => {
    switch (stage) {
      case 'sex':
        setStage('age');
        break;
      case 'age':
        setStage('district');
        break;
      case 'district':
        onComplete({ sex, ageRange, district });
        break;
      default:
        break;
    }
  };

  const renderStage = () => {
    switch (stage) {
      case 'sex':
        return (
          <>
            <Typography variant="h6" gutterBottom color="text.primary">
              Please select your sex:
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                value={sex}
                onChange={(e) => setSex(e.target.value)}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    marginY: 1,
                  },
                }}
              >
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label={
                    <Typography variant="body1" color="text.primary">
                      Female
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label={
                    <Typography variant="body1" color="text.primary">
                      Male
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio />}
                  label={
                    <Typography variant="body1" color="text.primary">
                      Other
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      case 'age':
        return (
          <>
            <Typography variant="h6" gutterBottom color="text.primary">
              Please select your age range:
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                value={ageRange}
                onChange={(e) => setAgeRange(e.target.value)}
                sx={{
                  display: 'grid',
                  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                  gap: 1,
                  '& .MuiFormControlLabel-root': {
                    margin: 0,
                    padding: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                  },
                }}
              >
                {[
                  'Under 18',
                  '18-24',
                  '25-30',
                  '31-40',
                  '41-50',
                  '50 and up',
                ].map((range) => (
                  <FormControlLabel
                    key={range}
                    value={range}
                    control={<Radio />}
                    label={
                      <Typography variant="body1" color="text.primary">
                        {range}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      case 'district':
        return (
          <>
            <Typography variant="h6" gutterBottom color="text.primary">
              Please select your district:
            </Typography>
            <FormControl component="fieldset" sx={{ width: '100%' }}>
              <RadioGroup
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    marginY: 1,
                    padding: 1,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                  },
                }}
              >
                {['Gasabo', 'Kicukiro', 'Nyarugenge'].map((dist) => (
                  <FormControlLabel
                    key={dist}
                    value={dist}
                    control={<Radio />}
                    label={
                      <Typography variant="body1" color="text.primary">
                        {dist}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  const isNextDisabled = () => {
    switch (stage) {
      case 'sex':
        return !sex;
      case 'age':
        return !ageRange;
      case 'district':
        return !district;
      default:
        return true;
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        py: { xs: 2, sm: 4 },
      }}
    >
      <Paper
        elevation={3}
        sx={{
          p: { xs: 2, sm: 3 },
          width: '100%',
          backgroundColor: 'background.paper',
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 2, sm: 3 },
          maxHeight: '90vh',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ flexShrink: 0 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: { xs: 1, sm: 2 },
            }}
          >
            <Avatar
              sx={{
                width: { xs: 48, sm: 56 },
                height: { xs: 48, sm: 56 },
                bgcolor: 'primary.main',
                mb: 2,
              }}
            >
              <LocalHospitalIcon />
            </Avatar>
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontSize: { xs: '1.25rem', sm: '1.5rem' },
                fontWeight: 600,
                color: 'text.primary',
              }}
            >
              Welcome to F&H Health Chat
            </Typography>
            <Typography
              variant="body2"
              align="center"
              color="text.secondary"
              sx={{ mb: 1 }}
            >
              Your private space for sexual health information
            </Typography>
          </Box>

          <Stepper
            activeStep={currentStep}
            alternativeLabel
            sx={{
              mb: { xs: 2, sm: 3 },
              '& .MuiStepLabel-label': {
                color: 'text.secondary',
                '&.Mui-active': {
                  color: 'primary.main',
                },
              },
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            px: { xs: 1, sm: 2 },
            pb: 2,
            scrollbarWidth: 'thin',
            scrollbarColor: `${theme.palette.primary.main} transparent`,
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: '2px',
            },
          }}
        >
          {renderStage()}
        </Box>

        <Box sx={{ flexShrink: 0, pt: 2 }}>
          <Button
            variant="contained"
            fullWidth
            onClick={handleNext}
            disabled={isNextDisabled()}
            sx={{
              py: 1.5,
              fontSize: '1rem',
            }}
          >
            {stage === 'district' ? 'Start Chat' : 'Next'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default UserProfile;
