import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  TextField,
  IconButton,
  Paper,
  Typography,
  Avatar,
  CircularProgress,
  AppBar,
  Toolbar,
  useTheme,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Rating,
  Snackbar,
  Alert,
  Tooltip,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import HistoryIcon from '@mui/icons-material/History';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ShareIcon from '@mui/icons-material/Share';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';
import LogoutIcon from '@mui/icons-material/Logout';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';

const Message = ({ content, isUser, isLoading, onDelete, onBookmark, isBookmarked, timestamp }) => {
  const theme = useTheme();
  const [menuAnchor, setMenuAnchor] = useState(null);
  
  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleDelete = () => {
    handleMenuClose();
    onDelete();
  };

  const handleBookmark = () => {
    handleMenuClose();
    onBookmark();
  };

  const handleShare = async () => {
    handleMenuClose();
    try {
      await navigator.clipboard.writeText(content);
      // Show success message (you can implement this)
    } catch (err) {
      console.error('Failed to copy message:', err);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        p: { xs: 2, sm: 3 },
        backgroundColor: isUser ? 'transparent' : 'rgba(16, 163, 127, 0.1)',
        borderBottom: `1px solid ${theme.palette.divider}`,
        maxWidth: '100%',
        transition: 'all 0.3s ease',
        position: 'relative',
        '&:hover .message-actions': {
          opacity: 1,
        },
      }}
    >
      <Avatar
        sx={{
          bgcolor: isUser ? theme.palette.secondary.main : theme.palette.primary.main,
          width: { xs: 28, sm: 32 },
          height: { xs: 28, sm: 32 },
        }}
      >
        {isUser ? 'U' : <LocalHospitalIcon />}
      </Avatar>
      <Box sx={{ flex: 1, maxWidth: 'calc(100% - 48px)' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CircularProgress size={20} />
            <Typography variant="body2" color="text.secondary">
              Generating response...
            </Typography>
          </Box>
        ) : (
          <React.Fragment>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
              <Typography variant="caption" color="text.secondary">
                {new Date(timestamp).toLocaleString()}
              </Typography>
              <Box className="message-actions" sx={{ opacity: 0, transition: 'opacity 0.2s' }}>
                <IconButton size="small" onClick={onBookmark}>
                  {isBookmarked ? <BookmarkIcon fontSize="small" /> : <BookmarkBorderIcon fontSize="small" />}
                </IconButton>
                <IconButton size="small" onClick={handleMenuOpen}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Typography
              component="div"
              sx={{
                '& p': { m: 0, overflowWrap: 'break-word' },
                '& ul': { mt: 1, mb: 1, pl: 2 },
                '& li': { mb: 0.5 },
                '& a': { color: theme.palette.primary.light },
                color: 'text.primary',
                fontSize: { xs: '0.9rem', sm: '1rem' },
                lineHeight: 1.6,
              }}
            >
              <ReactMarkdown>{content}</ReactMarkdown>
            </Typography>
          </React.Fragment>
        )}
      </Box>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
        <MenuItem onClick={handleBookmark}>
          <ListItemIcon>
            {isBookmarked ? <BookmarkIcon fontSize="small" /> : <BookmarkBorderIcon fontSize="small" />}
          </ListItemIcon>
          {isBookmarked ? 'Remove Bookmark' : 'Bookmark'}
        </MenuItem>
        <MenuItem onClick={handleShare}>
          <ListItemIcon>
            <ShareIcon fontSize="small" />
          </ListItemIcon>
          Copy to Clipboard
        </MenuItem>
      </Menu>
    </Box>
  );
};

const Chat = ({ userProfile, isMobile }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bookmarks, setBookmarks] = useState([]);
  const [showFeedback, setShowFeedback] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const messagesEndRef = useRef(null);
  const theme = useTheme();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Load chat history
  useEffect(() => {
    const loadHistory = async () => {
      try {
        const response = await axios.get('/api/chat/history', {
          params: { userProfile }
        });
        setMessages(response.data.messages);
        setBookmarks(response.data.bookmarks || []);
      } catch (error) {
        console.error('Error loading chat history:', error);
      }
    };
    loadHistory();
  }, [userProfile]);

  const handleSend = async () => {
    if (!input.trim() || isLoading) return;

    const userMessage = input.trim();
    const timestamp = new Date().toISOString();
    setInput('');
    setMessages(prev => [...prev, { content: userMessage, isUser: true, timestamp }]);
    setIsLoading(true);

    try {
      const response = await axios.post('/api/chat', {
        message: userMessage,
        userProfile,
      });

      setMessages(prev => [...prev, {
        content: response.data.message,
        isUser: false,
        timestamp: new Date().toISOString()
      }]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [
        ...prev,
        {
          content: 'Sorry, there was an error processing your message. Please try again.',
          isUser: false,
          timestamp: new Date().toISOString()
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleDeleteMessage = async (index) => {
    try {
      await axios.delete('/api/chat/message', {
        data: { messageId: messages[index].id }
      });
      setMessages(prev => prev.filter((_, i) => i !== index));
      setSnackbar({
        open: true,
        message: 'Message deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete message',
        severity: 'error'
      });
    }
  };

  const handleBookmarkMessage = async (index) => {
    const message = messages[index];
    const isCurrentlyBookmarked = bookmarks.includes(index);

    try {
      if (isCurrentlyBookmarked) {
        await axios.delete('/api/chat/bookmark', {
          data: { messageId: message.id }
        });
        setBookmarks(prev => prev.filter(i => i !== index));
      } else {
        await axios.post('/api/chat/bookmark', {
          messageId: message.id
        });
        setBookmarks(prev => [...prev, index]);
      }
      setSnackbar({
        open: true,
        message: isCurrentlyBookmarked ? 'Bookmark removed' : 'Message bookmarked',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update bookmark',
        severity: 'error'
      });
    }
  };

  const handleSubmitFeedback = async () => {
    try {
      await axios.post('/api/chat/feedback', {
        rating,
        feedback: feedbackText,
        userProfile
      });
      setShowFeedback(false);
      setSnackbar({
        open: true,
        message: 'Thank you for your feedback!',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to submit feedback',
        severity: 'error'
      });
    }
  };

  const handleClearHistory = async () => {
    try {
      await axios.delete('/api/chat/history', {
        data: { userProfile }
      });
      setMessages([]);
      setBookmarks([]);
      setSnackbar({
        open: true,
        message: 'Chat history cleared',
        severity: 'success'
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to clear history',
        severity: 'error'
      });
    }
  };

  return (
    <React.Fragment>
      <AppBar 
        position="static" 
        color="transparent" 
        elevation={0}
        sx={{ 
          borderBottom: `1px solid ${theme.palette.divider}`,
          backdropFilter: 'blur(8px)',
        }}
      >
        <Toolbar sx={{ minHeight: { xs: 48, sm: 56 } }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setDrawerOpen(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            F&H Health Chat
          </Typography>
          <Tooltip title="Give Feedback">
            <IconButton color="inherit" onClick={() => setShowFeedback(true)}>
              <StarIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          scrollbarWidth: 'thin',
          scrollbarColor: `${theme.palette.primary.main} transparent`,
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '3px',
          },
        }}
      >
        {messages.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              p: 3,
              textAlign: 'center',
              color: 'text.secondary',
            }}
          >
            <LocalHospitalIcon sx={{ fontSize: 48, mb: 2, color: 'primary.main' }} />
            <Typography variant="h6" gutterBottom>
              Welcome to Fem and Hom Health
            </Typography>
            <Typography variant="body2">
              Ask me anything about sexual health, STIs, or UTIs. Your privacy and confidentiality are guaranteed.
            </Typography>
          </Box>
        ) : (
          messages.map((message, index) => (
            <Message
              key={index}
              {...message}
              onDelete={() => handleDeleteMessage(index)}
              onBookmark={() => handleBookmarkMessage(index)}
              isBookmarked={bookmarks.includes(index)}
            />
          ))
        )}
        {isLoading && (
          <Message
            content="..."
            isUser={false}
            isLoading={true}
          />
        )}
        <div ref={messagesEndRef} />
      </Box>

      <Paper
        elevation={3}
        sx={{
          p: { xs: 1.5, sm: 2 },
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: 'background.paper',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', gap: 1 }}>
          <TextField
            fullWidth
            multiline
            maxRows={4}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type your message..."
            disabled={isLoading}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'rgba(255,255,255,0.05)',
                fontSize: { xs: '0.9rem', sm: '1rem' },
                p: { xs: 1, sm: 1.5 },
              },
            }}
          />
          <IconButton
            onClick={handleSend}
            disabled={!input.trim() || isLoading}
            color="primary"
            sx={{
              alignSelf: 'flex-end',
              p: { xs: 1, sm: 1.5 },
              '& .MuiSvgIcon-root': {
                fontSize: { xs: 20, sm: 24 },
              },
            }}
          >
            <SendIcon />
          </IconButton>
        </Box>
      </Paper>

      {/* Side Drawer */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{
            width: 250,
            bgcolor: 'background.paper',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ p: 2, borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Typography variant="h6">Menu</Typography>
          </Box>
          <List>
            <ListItem button onClick={() => setShowFeedback(true)}>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary="Give Feedback" />
            </ListItem>
            <ListItem button onClick={handleClearHistory}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Clear History" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => window.location.reload()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Start New Chat" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* Feedback Dialog */}
      <Dialog open={showFeedback} onClose={() => setShowFeedback(false)}>
        <DialogTitle>How was your experience?</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
            <Rating
              value={rating}
              onChange={(_, newValue) => setRating(newValue)}
              size="large"
            />
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder="Tell us what we can improve..."
              value={feedbackText}
              onChange={(e) => setFeedbackText(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowFeedback(false)}>Cancel</Button>
          <Button onClick={handleSubmitFeedback} variant="contained">
            Submit Feedback
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Chat;
