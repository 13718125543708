import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Chat from './components/Chat';
import UserProfile from './components/UserProfile';
import { Box, useMediaQuery } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#10a37f',
      light: '#1cd6a7',
      dark: '#0a8c6c',
    },
    secondary: {
      main: '#1a73e8',
    },
    background: {
      default: '#0f1419',
      paper: '#1a1f24',
    },
    text: {
      primary: '#ffffff',
      secondary: 'rgba(255, 255, 255, 0.7)',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '1rem',
          padding: '10px 20px',
          borderRadius: '8px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});

function App() {
  const [userProfile, setUserProfile] = useState(null);
  const [stage, setStage] = useState('profile');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleProfileComplete = (profile) => {
    setUserProfile(profile);
    setStage('chat');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.default',
          overflow: 'hidden',
        }}
      >
        {stage === 'profile' ? (
          <UserProfile onComplete={handleProfileComplete} isMobile={isMobile} />
        ) : (
          <Chat userProfile={userProfile} isMobile={isMobile} />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default App;
